import Generics from "../components/Generics";
// Import Icons
import analytics from "../assets/icons/sidebar/analytics.svg";
import leads from "../assets/icons/sidebar/order.svg";
import finance from "../assets/icons/sidebar/finance.svg";
import student from "../assets/icons/sidebar/student.svg";
import groups from "../assets/icons/sidebar/group.svg";
import courses from "../assets/icons/sidebar/course.svg";
import hr from "../assets/icons/sidebar/hr.svg";
import settings from "../assets/icons/sidebar/settings.svg";
// Use ID
import id from "../hooks/useIdGen";
const useId = id();

export const sidebar = [
  {
    id: useId.next().value,
    title: "Statistika",
    path: "/statistika",
    hidden: false,
    iconPath: analytics,
    element: <Generics />,
  },
  {
    id: useId.next().value,
    title: "Lidlar",
    path: "/lids",
    hidden: false,
    iconPath: leads,
    element: <Generics />,
    children: [
      {
        id: useId.next().value,
        title: "First",
        path: "/lids/first",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Second",
        path: "/lids/second",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Third",
        path: "/lids/third",
        hidden: false,
        element: <Generics />,
      },
    ],
  },
  {
    id: useId.next().value,
    title: "Moliya",
    path: "/moliya",
    hidden: false,
    iconPath: finance,
    element: <Generics />,
    children: [
      {
        id: useId.next().value,
        title: "First",
        path: "/moliya/first",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Second",
        path: "/moliya/second",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Third",
        path: "/moliya/third",
        hidden: false,
        element: <Generics />,
      },
    ],
  },
  {
    id: useId.next().value,
    title: "Talaba",
    path: "/talaba",
    hidden: false,
    iconPath: student,
    element: <Generics />,
    children: [
      {
        id: useId.next().value,
        title: "First",
        path: "/talaba/first",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Second",
        path: "/talaba/second",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Third",
        path: "/talaba/third",
        hidden: false,
        element: <Generics />,
      },
    ],
  },
  {
    id: useId.next().value,
    title: "Guruhlar",
    path: "/guruhlar",
    hidden: false,
    iconPath: groups,
    element: <Generics />,
    children: [
      {
        id: useId.next().value,
        title: "First",
        path: "/guruhlar/first",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Second",
        path: "/guruhlar/second",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Third",
        path: "/guruhlar/third",
        hidden: false,
        element: <Generics />,
      },
    ],
  },
  {
    id: useId.next().value,
    title: "Kurslar",
    path: "/kurslar",
    hidden: false,
    iconPath: courses,
    element: <Generics />,
    children: [
      {
        id: useId.next().value,
        title: "First",
        path: "/kurslar/first",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Second",
        path: "/kurslar/second",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Third",
        path: "/kurslar/third",
        hidden: false,
        element: <Generics />,
      },
    ],
  },
  {
    id: useId.next().value,
    title: "HR",
    path: "/hr",
    hidden: false,
    iconPath: hr,
    element: <Generics />,
    children: [
      {
        id: useId.next().value,
        title: "First",
        path: "/hr/first",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Second",
        path: "/hr/second",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Third",
        path: "/hr/third",
        hidden: false,
        element: <Generics />,
      },
    ],
  },
  {
    id: useId.next().value,
    title: "Sozlamalar",
    path: "/sozlamalar",
    hidden: false,
    iconPath: settings,
    element: <Generics />,
    children: [
      {
        id: useId.next().value,
        title: "First",
        path: "/sozlamalar/first",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Second",
        path: "/sozlamalar/second",
        hidden: false,
        element: <Generics />,
      },
      {
        id: useId.next().value,
        title: "Third",
        path: "/sozlamalar/third",
        hidden: false,
        element: <Generics />,
      },
    ],
  },
  { id: useId.next().value, title: `Signin`, path: "/signin", hidden: true },
  { id: useId.next().value, title: "Signup", path: "/signup", hidden: true },
];

import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0px 8px 19px #f5f6f8;
`;

export const Wrapper = styled.div`
  max-width: 1160px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
`;

export const Left = styled.div`
  width: 500px;
  height: 40px;
  position: relative;
  input {
    font-family: "Montserrat";
    font-weight: 500;
    width: 100%;
    height: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 10px 16px 10px 42px;
    outline: 1px solid transparent;
    color: #777;
  }
  ::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: #bbc3cd;
  }
  img {
    position: absolute;
    top: 10px;
    left: 16px;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Time = styled.div`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #253e5f;
  span {
    color: #bbc3cd;
  }
`;

export const DropDownContainer = styled.div`
  position: relative;
`;
export const DropDownHeader = styled.div`
  width: max-content;
  padding: 10px 20px;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;
export const DropDownListContainer = styled.div`
  position: absolute;
  right: 0;
  top: 49px;
  z-index: 99;
`;

export const DropDownList = styled.ul`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  list-style-type: none;
  border-radius: 5px;
  text-align: center;
`;

export const ListItem = styled.li`
  margin: 10px 0;
  transition: all 0.3s linear;
  cursor: pointer;
`;

import { useState } from "react";
import {
  Container,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  Left,
  ListItem,
  Right,
  Time,
  Wrapper,
} from "./style";
import SearchIcon from "../../assets/icons/navbar/search-icon.svg";
import DownArrowIcon from "../../assets/icons/navbar/down-arrow.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./style.css";

export const Navbar = () => {
  const getDate = new Date();
  const time = `${getDate.getHours()}:${getDate.getMinutes()} `;

  const options = ["Uzbek", "Russian", "English"];

  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);
  const toggle = () => setOpen(!open);

  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value);
    setOpen(false);
  };

  const [date, setDate] = useState<any>(new Date());

  return (
    <Container>
      <Wrapper>
        <Left>
          <img src={SearchIcon} alt="search-icon" />
          <input type="text" placeholder="Search" />
        </Left>
        <Right>
          <Time>
            {time}
            <span>{getDate.getHours() >= 12 ? "PM" : "AM"}</span>
          </Time>
          <DropDownContainer>
            <DropDownHeader onClick={toggling}>
              {date.toDateString()}
              <img src={DownArrowIcon} alt="down-arrow-icon" />
            </DropDownHeader>
            {isOpen && (
              <DropDownListContainer>
                <Calendar
                  onChange={() => setDate}
                  value={date}
                  selectRange={false}
                  defaultValue={date}
                  onClickDay={(value) => {
                    setDate(value);
                    setIsOpen(false);
                  }}
                />
              </DropDownListContainer>
            )}
          </DropDownContainer>
          <DropDownContainer>
            <DropDownHeader onClick={toggle}>
              {selectedOption || "Uzbek"}
              <img src={DownArrowIcon} alt="down-arrow-icon" />
            </DropDownHeader>
            {open && (
              <DropDownListContainer style={{ width: "120px" }}>
                <DropDownList>
                  {options.map((option, index) => (
                    <ListItem onClick={onOptionClicked(option)} key={index}>
                      {option}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </DropDownContainer>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;

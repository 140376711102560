// import { useId } from "react";

// export const useIdGenerator = () => {
//   const id = useId();

//   return id;
// };

// export default useIdGenerator;
export function* useIdGenerator() {
  let a = 0;
  while (true) yield a++;
}

export default useIdGenerator;

import React from "react";
import { Container } from "./child-link.style";

interface Props {
  item?: any;
}

const ChildLink: React.FC<Props> = ({ item }: any) => {
  return <Container to={item.path}>{item.title}</Container>;
};

export default ChildLink;

import React from "react";
import { Link, Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import {
  BodyContainer,
  Container,
  ForLogo,
  Links,
  Profile,
  ProfileLeft,
  ProfileRight,
  SidebarContainer,
  Badge,
} from "./style";
import logo from "../../assets/images/logo.svg";
import signOut from "../../assets/icons/sign-out.svg";
import profileImg from "../../assets/images/profile-image.svg";
import { sidebar } from "../../utils/sidebar";
import ChildLink from "./child-link.component";
import ParentLink from "./parent-link.component";

export const Sidebar = () => {
  return (
    <Container>
      <SidebarContainer>
        <div>
          <ForLogo>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </ForLogo>
          <Profile>
            <ProfileLeft>
              <img src={profileImg} alt="profile-img" />
              <Badge />
            </ProfileLeft>
            <ProfileRight>
              <h2>Sardorbek Muhtorov</h2>
              <h3>s.muhtorov@gmail.com</h3>
            </ProfileRight>
          </Profile>
          <Links>
            {sidebar.map((item, index) =>
              item.hidden ? null : item.children ? (
                <ParentLink item={item} key={item.id || index} />
              ) : (
                <ChildLink item={item} key={item.id || index} />
              )
            )}
          </Links>
        </div>
        <ForLogo
          onClick={() => window.confirm("Ishonchingiz komilmi?")}
          signout="true"
        >
          <img src={signOut} alt="sign out" /> Chiqish
        </ForLogo>
      </SidebarContainer>
      <BodyContainer>
        <Navbar />
        <Outlet />
      </BodyContainer>
    </Container>
  );
};

export default Sidebar;

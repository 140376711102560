import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { sidebar } from "../utils/sidebar";

export const Root = () => {
  return (
    <Routes>
      <Route element={<Sidebar />} path="/">
        {sidebar.map((item, index) => (
          <React.Fragment key={index}>
            <Route path={item.path} element={item.element} />
            {item?.children?.map((item, index) => (
              <Route
                path={item.path}
                element={item.element}
                key={item?.id || index}
              />
            ))}
          </React.Fragment>
        ))}
      </Route>
      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  );
};

export default Root;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const SidebarContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: var(--widthSidebar);
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ForLogo = styled.div<{ signout?: any }>`
  padding: 16px 24px;
  height: var(--heightNavbar);
  display: flex;
  align-items: center;
  border-top: 1px solid #f8fafc;
  border-bottom: 1px solid #f8fafc;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: #fff;
  color: var(--darkBlue);
  gap: 16px;

  img {
    width: ${({ signout }) => signout && "18px"};
  }
`;

export const Links = styled.div`
  max-height: calc(100vh - 120px - 72px);
  overflow-y: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--darkBlue);

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const Profile = styled.div`
  margin-block: 12px;
  padding-inline: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const ProfileLeft = styled.div`
  width: 48px;
  height: 48px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Badge = styled.div`
  width: 10px;
  height: 10px;
  background-color: #52c41a;
  border-radius: 50%;
  position: absolute;
  bottom: 1px;
  right: 1px;
`;

export const ProfileRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--darkBlue);
  }

  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #bbc3cd;
  }
`;

export const BodyContainer = styled.div`
  flex: 1;
  background-color: aliceblue;
`;

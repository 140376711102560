import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled(NavLink)`
  cursor: pointer;
  padding-block: 12px;
  padding-inline: calc(25px + 34px) 25px;
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  color: var(--darkBlue);
  transition: 0.2s linear;

  &.active {
    color: var(--lightBlue);
  }
`;

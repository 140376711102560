import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled(NavLink)`
  cursor: pointer;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  text-decoration: none;
  color: var(--darkBlue);
  transition: 0.2s linear;

  &.active {
    background-color: #f8fafc;
    color: var(--lightBlue);
  }
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  img {
    width: 18px;
  }
`;

export const RightSide = styled.div<{ rotate: string }>`
  transform: rotate(${(props) => (props.rotate === "true" ? "90deg" : "0")});
`;

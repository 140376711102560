import React, { useLayoutEffect, useState } from "react";
import { Container, LeftSide, RightSide } from "./parent-link.style";
import arrow from "../../assets/icons/link-arrow.svg";
// import { ReactComponent as Arrow } from "../../assets/icons/link-arrow.svg";
import ChildLink from "./child-link.component";
import { useLocation } from "react-router-dom";

interface Props {
  item?: any;
}

const ParentLink: React.FC<Props> = ({ item }: any) => {
  const location = useLocation();
  const [opened, setOpened] = useState(
    window.location.pathname.slice(0, item.path.length) === item.path &&
      (window.location.pathname.at(item.path.length) === undefined ||
        window.location.pathname.at(item.path.length) === "/")
  );

  useLayoutEffect(() => {
    setOpened(
      window.location.pathname.slice(0, item.path.length) === item.path &&
        (window.location.pathname.at(item.path.length) === undefined ||
          window.location.pathname.at(item.path.length) === "/")
    );
  }, [location]);

  const handleClick = (e: any) => {
    if (opened) e.preventDefault();
    setOpened((p) => !p);
  };

  return (
    <>
      <Container to={item.path} onClick={handleClick}>
        <LeftSide>
          <img src={item?.iconPath} alt={item?.iconPath + "-icon"} />{" "}
          {item.title}
        </LeftSide>
        <RightSide rotate={`${opened}`}>
          <img src={arrow} alt="open-close" />
          {/* <Arrow /> */}
        </RightSide>
      </Container>
      <>
        {opened
          ? item.children.map((value: any, index: number) => (
              <ChildLink item={value} key={value.id || index} />
            ))
          : null}
      </>
    </>
  );
};

export default ParentLink;
